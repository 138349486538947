import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "CSS Challenge Day 2 - Animated sunrise and sunset over pyramid",
  "description": "How to create animated sunrise and sunset over a pyramid with css",
  "date": "2019-08-12",
  "image": "sunrise.jpg",
  "tags": ["css", "tutorial", "frontend"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I have been starting to take CSS Challenges recently. Some of them are actually very interesting and entertaining to learn and create.
Today, I will write about Day 2 challenge which is to create an animated sunrise and sunset over a pyramid using CSS`}</p>
    <h2>{`Demo`}</h2>
    <p>{`You can take a look at the original demo link below.`}</p>
    <p><a parentName="p" {...{
        "href": "https://100dayscss.com/?dayIndex=2"
      }}>{`https://100dayscss.com/?dayIndex=2`}</a></p>
    <h2>{`Create static components`}</h2>
    <h3>{`Creating the frame`}</h3>
    <p>{`To begin with, let's create the html structure.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`container`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`frame`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`sun`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`sky`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`pyramid-left pyramid-side`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`pyramid-right pyramid-side`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`ground`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`shadow`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Next is the basic css styling for these components. First we need to style the body to expand the whole width and height of current view.
Then, we create a container with a black background.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`body `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100vw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100vh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` flex`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`justify-content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`align-items`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$container-size`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 400px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.container `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$container-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$container-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` flex`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-shadow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1px 2px 10px 0px `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rgba`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0.3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #272c34`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`justify-content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`align-items`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <p>{`Next, we will style a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`frame`}</code>{` to contain all of our components.
Let's say we want the frame to be about 200px less in size of our container. You can choose whichever number as you wish.
To make the frame a round shape, it is important to have `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`overflow: hidden`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`border-radius: 50%`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$frame-size`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$container-size`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` 200px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.frame `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$frame-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$frame-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 50%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #000`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`overflow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` hidden`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Here is what we got`}</p>
    <div {...{
      "style": {
        "textAlign": "center",
        "margin": "0 auto"
      }
    }}>{`
    `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "796px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/55941cb16b27f05f950bcd6fc1447a3f/d48f1/demo1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAABRklEQVQ4y71U207CQBBtt4tACylpIl6imBaxWwRsFHjCRJ/w/39o3LNkdSnbrYkNDyczmTlzMjN78fLFmtqEdxbBp+c3BdO3xRoF64rEcqPg4tQKmrZYbWk2f6V0tlCAj5iNeyJoI4xvJtQLB8Q7FwrwEXPVeNVxdXKUXJLvMwoCTgHvHCB9xEbJ2Lom68gY6fouJZ+xn86qQA4ccJ0j62Q0iInJblCMzrSQ9pEDx9nhIbihqXihbi88EqoCOXCmonTvEPaxKKnbj2QRdwhyyQkVt/FQcN+GcUKMBfUjyxw44DYeilhu6T7N1Z5sYyOGHDjgNh6KFr26fVCFvuwG1vSR02INh7I+Ep1kBcXyPvajoQJ8xEyx2h3aReUbXv0Ww6/uzfmWbaJmwV9+nfb/wywvKRMtQWp5759ftPvY0w72X9gTtL4BXxw+0kgR3YgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "frame",
            "title": "frame",
            "src": "/static/55941cb16b27f05f950bcd6fc1447a3f/d48f1/demo1.png",
            "srcSet": ["/static/55941cb16b27f05f950bcd6fc1447a3f/a2ead/demo1.png 259w", "/static/55941cb16b27f05f950bcd6fc1447a3f/6b9fd/demo1.png 518w", "/static/55941cb16b27f05f950bcd6fc1447a3f/d48f1/demo1.png 796w"],
            "sizes": "(max-width: 796px) 100vw, 796px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <h4>{`Creating the sky, ground`}</h4>
    <p>{`The proportion of the sky and ground could be 70 and 30 respectively. You can adjust the number for your own reference.
In this tutorial, I found these two numbers feasible to use.
We would want to set both components `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`position: relative`}</code>{` to make it possible to position their children components with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`position:absolute`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.sky `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #00d6ff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 70%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.ground `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 30%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #f7ee5a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h4>{`Creating the pyramid`}</h4>
    <p>{`When I first tried this challenge, I thought of using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`:after`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`:before`}</code>{` along with creating borders for the elements to make the triangles of the pyramid.
If you are curious about that technique, you can read more`}</p>
    <a alt="triangle-techniques-with-css" href="http://blog.michelledinan.com/08/2012/drawing-triangles-with-css/#obtuse" target="_blank">here</a>.
However, after a bit of the hassle, I discovered that there was an easier way to do it, which is to use
    <a alt="clip-path" href="https://developer.mozilla.org/en-US/docs/Web/CSS/clip-path">clip-path</a>. `clip-path` allows 
us to create a wide variety of shapes for our components.
    <p>{`To create the pyramid, instead of creating two obtuse triangles leaning on each other, we can create a normal triangle and having an obtuse one on top of it.
By doing this, it would save us the pain of keeping two triangles matching angles with each other. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$pyramid-width-left`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 130px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$pyramid-height`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 70px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$pyramid-left-position`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 40px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.pyramid-left `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #f4f4f4`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.pyramid-right `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #dddada`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` 2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` 2 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`To give us the freedom of position of two triangles, it would make sense to have `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`position: absolute`}</code>{` for such components.
In the code, let's make the height, left position and the width of the big triangle into variables.
They would make it possible for us create relative position between the two triangles.
Let's make two blocks, one for big triangle and one for smaller one.`}</p>
    <p>{`Here we have a grey box being on top of white one. `}</p>
    <div {...{
      "style": {
        "textAlign": "center",
        "margin": "0 auto",
        "marginBottom": "20px"
      }
    }}>{`
    `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "872px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c4d5abecda41fd608e0dbd924f3098ca/65654/demo2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "99.61389961389962%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAC20lEQVQ4y52Uy0tUcRTH76JFZG2KUlEn53Uf83IejpNaBjFFL0RSQsgg6EG0aCFEm1ZGIIJ/g6mZYvkmWkSriGlRofNgKkcdndEwnZDRSW6p3879zURpo9O0+HIvv985n9/33PO7h1tJJDA4/Ax9A0PoHxymZ/YaGBpBT28fJqfC4KZnItBJNhSqJai0JpIxa6n5Euw7UIiOR93gZiJRWByVEEylkCxlEM3O9Nphz2QrR75KwOOeJwowwhb0BjuDbpYTvMVFKkuJ3gmwNc5Q4kJuoT49kFeCjI5kIq1JagGSRoSolSAV8xAFC+2VsRgWtwnYm8ZhCibyZuhtFTjU2Iy9bS+R0/kK+x+0QX2iGqLOkDzwX4AMRi40VWex+/knCgC4MGmKNA3sGl1FwZXGFDTpdHtg6lSl1D1P3yZhoz/Aja2B866T6DmurMnQuGsgUBWKge2BSifpZNXFGyzRHZJxJyrjdvQ7bobiuBqM4ZZ/HvoIkNPcCQN9U6VROwBd1AQR+XdbwYWAh5+XgdUl4Fscy7NhzH3wI/HRi4bwKrguDwySNcM3VIAaAfnUCMVh6yyAxBq+rmxgOrKIQDCMaHACZyap7HYPTAKVbMpQMq83QzxXh2MTYxief4P1+Gssxz34MvcCM5MjmB8fRMvCe+juNUEoFlnOjl0WzaVQ66zob3eTvXokFuuwslBLqmPvWKtH2FeNcpcNWskBMeM9JOkMpexX6+o4D3npEiBfZtpINOCdpxbu0xVQ6R2Q6HDemOkeKteGQR1Q6eyouVCJ+00n0dJ8CteuH2d7xQQTzb9jMzr8FagkHSZonsqG3CKaSBo72/sTlgYYhdlekQr8+8eXLE4YS0hWpZtOBtsaY7QeQV4RnxoONA+NVhe0oo1BFafZShl7Bwu06OomhxFyaHdVkQsXOS1n5Wcrq/MoijRGNrU5WZYRCATh9fnh8wfg8/2HKG90zItYLIafvwRUejHJwK0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "frame",
            "title": "frame",
            "src": "/static/c4d5abecda41fd608e0dbd924f3098ca/65654/demo2.png",
            "srcSet": ["/static/c4d5abecda41fd608e0dbd924f3098ca/a2ead/demo2.png 259w", "/static/c4d5abecda41fd608e0dbd924f3098ca/6b9fd/demo2.png 518w", "/static/c4d5abecda41fd608e0dbd924f3098ca/65654/demo2.png 872w"],
            "sizes": "(max-width: 872px) 100vw, 872px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <p>{`Here is the fun part, let's shape these boxes into triangles with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`clip-path`}</code>{`. We will use a property called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`polygon`}</code>{`.
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`polygon`}</code>{` allows us to pass in as many pairs of coordinates by percentages of the component to clip the box into shape.
(0, 0) is the top left of the component.`}</p>
    <p>{`To clip a box into a triangle, we need three points, I have made two images to make it easier to illustrate how we can do it.`}</p>
    <div {...{
      "style": {
        "textAlign": "center",
        "margin": "0 auto",
        "marginBottom": "20px"
      }
    }}>{`
    `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "854px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/80d8341e5d50f0f2ae2f2ac3646e4478/c3fd4/demo4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.301158301158296%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABqklEQVQoz4WTi27iMBBF8/8/tkBBQBJW2xYa2rxogMTOg5CX7844BLZqo7V0da14fDJjj42u61AUBfI8R13XqKqKVKMsS+28Pgyes9rB28HbuxtCCLiuiyxNkWUZUvKiyBFFkZ7zCA6fOqZtGyil9GZ1gw4+yEjiBIfPEO+xAyd+w57knNkd7dvjDp43w5/3BV5p7px2eDttSTut7fEVpywCFPoMpZComivMYI21/5Dpm1h6JtzwFyCnuMRT2P4CK9+6xZrkHLOEn7gPYJZmKMocm9CG/Y/WgU2lTgk2g5IL8jku5wl+B0tY4eYezz8OhPcVeLkWtGjBCqw7zGeYmGmQEk+keQ89EdRfwrxBuZofgZtwHMaOH6CcqTkOtLEayaz3p++ZUvmrbyVnfIYFpW71MMmw/szGpNjT/kwtdw43IWCnHn14pVs+ngmSTNAlM9L0v2rp1hlaxhOcc0/3K/epcaCm9X0PH/tnVKVE1xRo65xUfFFX998HV+0FMong7l/gfTgIglA3vcHdzWXHsUDTdJw5+LWNSq8r2gx6nhWSREJQL0sp9Ev5C1jshqVdxZhgAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "clip-path-demo",
            "title": "clip-path-demo",
            "src": "/static/80d8341e5d50f0f2ae2f2ac3646e4478/c3fd4/demo4.png",
            "srcSet": ["/static/80d8341e5d50f0f2ae2f2ac3646e4478/a2ead/demo4.png 259w", "/static/80d8341e5d50f0f2ae2f2ac3646e4478/6b9fd/demo4.png 518w", "/static/80d8341e5d50f0f2ae2f2ac3646e4478/c3fd4/demo4.png 854w"],
            "sizes": "(max-width: 854px) 100vw, 854px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <div {...{
      "style": {
        "textAlign": "center",
        "margin": "0 auto",
        "marginBottom": "20px"
      }
    }}>{`
    `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "538px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/307dc14b47eea21e5809cfaf5b895629/9516f/demo5.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.64478764478764%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAACLklEQVQ4y42UCXPaMBCF+f+/qedM2wAJpkzDbeoD44Mj1CbGoOt1JRtSUycTzbzZtY7P+yxZLSkltBhjEBSVAmQlVYm6r8/1XJm1SpVRq0X9yPMcltXH025DswpwdoRgOSQ/Gpmc3eR6Di+uoCtQCAEtzgo49iP+zL8Ay4/g3mcI/9MrojHvA85Rm1yV1WmGARqyLhMM29UMw987FH4PiLpQ4T3wmlYd8MSitapuuSTrDo5TZGHipZj4KY5elxa2oVYEpsU1hTS2ahOwZ4C1Ci8JFxwyecDScTCMgan3VEKjjoEi7FTwbg0oqspqlnXJQgpgY2HnDDAOOaYJqNo9Qcle1K5Dr8A3LAuyLNd9ML+DsZ9hnghMYtUMvQE2WhZkma9/AsF3uG6AMdmeR4yg/1f6Lst6U3hCwPAH1u4Io1BiFnPMIt4IfZdlA6SJhf+A0TInoMQ0aoKS7aBT7rJ8y3LSN+dLBXdYuLGBaFgjNLwDi61rZY2WxXpAdsoKIneOcaTM5sxjQdXqSGDa/am/R6GP1LpXs6xj3XJ8D+npX+8rssU3DJwDhgEjnSsxjCg+riR+2Rvsl31aW/26nJv4Ylkf7jwBTz3I5wAoYmT5GWkBbNMTdtnZaJuWcZNx7NNn3LZW7fpRZechP2FmO/B9D1EYVFNVJflPBDzPg23bRnEcv1R42Rh90R0OGRYLG0EQGJlPom+l6uMLUUbd9LjruvRyvwlY5retac4lv21/AT9gXfvCXVWvAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "clip-path-demo",
            "title": "clip-path-demo",
            "src": "/static/307dc14b47eea21e5809cfaf5b895629/9516f/demo5.png",
            "srcSet": ["/static/307dc14b47eea21e5809cfaf5b895629/a2ead/demo5.png 259w", "/static/307dc14b47eea21e5809cfaf5b895629/6b9fd/demo5.png 518w", "/static/307dc14b47eea21e5809cfaf5b895629/9516f/demo5.png 538w"],
            "sizes": "(max-width: 538px) 100vw, 538px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <p>{`Let's put these coordinates into `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`clip-path`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$pyramid-width-left`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 130px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$pyramid-height`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 70px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$pyramid-left-position`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 40px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.pyramid-left `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #f4f4f4`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`clip-path`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`polygon`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`0% 100%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` 100% 100%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` 50% 0%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.pyramid-right `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #dddada`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` 2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` 2 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`clip-path`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`polygon`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`0% 0%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` 100% 100%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` 30% 100%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "style": {
        "textAlign": "center",
        "margin": "0 auto",
        "marginBottom": "20px"
      }
    }}>{`
    `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/631d926ff47596925eda76ed3c0e7098/1b1d5/demo6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAACp0lEQVQ4y6WVy08TURTGR2FlMNHEBDVISx/TmXZK6RMssBCMBF8b3DTF+AcI/AO68LExxhijKzeGsFHjmhKNEReuTIxtZ2qrRJEGE6gJLQgqncLnubcthqSPVBdf7kzuub/z3TN3zhX0QgGJD0lEYypicY2kNiiN6300jlwuByGbzaK7bxAW2Q3J6YdN8TUsuTOAY2YHIjMvIKysZBEIDsAkdfFJ0eFtWMxIW4cd05HnBGQOewdhruaQsoud3TuyVYjhDk0OTM+UgIHeAZjLDlkQy6z4+bNkliF32IoySbBRYtEZKMWUgX4C2isDeSBzIdO75EJbeAwHb03hwN2naB2/AauvH5JVKcZQbF0gc8ZgVk8fWiZnIXwBhPmSvgLNrxdhOD9KUMeO0+pAysoyMwf7H0YgfAP2qDoEtUDawt54noOb3mRgPj5IO+jiBqoDqbgss+FMCEKKHGlbEBLbJMCYKvCxKbYJIQ0cuvqA11WkNdWBrm5e+MNj17mTZo0cqdsYX8xjKbMMf+IHJdlGE5WhZfIVJNHJd1T8yjWArZevFWsXA0YXgPyGjt+5Daif0nColOQzsO/RLGRRqQOkCRv7gsMjcM/N4dJ8EuurUfxaiyK7FsNKehZvUy8xtJSGeOUmRKPM11TdsuT0cRktbjyeGgZwET+/h7CaCWE9UxyRD2MhcQGBHh+t8fL42ueQZJG9ULp8ePbkLLbWw4A+SiDSZhjquxGcPhdEu8XDYWLNY1P6l1mgmaBGqxehcD/u3D6F+/eGMDFxAorbTzDvDqz+n6L8DWSL2kweHDG4cdTo5qPVvhtWEcibg1S5OcidPthdpNLIYPWbQ3D3lv+rfbEG29N/kjdYlolNNio7nd/2coPVdR3J1EfEVQ2qlvgnaSR2HbAr4A/YTltAguUtVwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "the pyramid",
            "title": "the pyramid",
            "src": "/static/631d926ff47596925eda76ed3c0e7098/1b1d5/demo6.png",
            "srcSet": ["/static/631d926ff47596925eda76ed3c0e7098/a2ead/demo6.png 259w", "/static/631d926ff47596925eda76ed3c0e7098/6b9fd/demo6.png 518w", "/static/631d926ff47596925eda76ed3c0e7098/1b1d5/demo6.png 876w"],
            "sizes": "(max-width: 876px) 100vw, 876px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <h4>{`Creating the sun and the pyramid's shadow`}</h4>
    <p>{`Now, let's create css for the sun.
The first position of the sun is very important here because it determines the movement of the sun in the animation.
The center of the sun needs to be in the middle of the frame. I will explain more why when we get to the animation part.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$sun-size`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 40px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.sun `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`z-index`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$sun-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$sun-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`overflow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` hidden`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #fd7e08`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$frame-size`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` 2 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$sun-size`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` 2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`As for the shadow, we will do the same as we created the pyramid.
To create a triangle shape, let's create a div block below the pyramid.
It will have the width of its container. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$shadow-left-position`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 50px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.shadow `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #80808080`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 50%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip-path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`polygon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 140% 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "style": {
        "textAlign": "center",
        "margin": "0 auto",
        "marginBottom": "20px"
      }
    }}>{`
    `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "792px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bca4a9dc2932d69617fb83ebc374bad6/9a86a/demo7.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "96.52509652509652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABYlAAAWJQFJUiTwAAACdElEQVQ4y72US0iUURiGzxRE14W06arO7b/MjI5zUzK8JBFElASRF1woBJXQQlsL1SJatIlWrSIpiFbRJpKgFumiIGl0ZiqZvKQpKVlpjObMPJ35Z6LJZpyNtPg4H+f/zvO95z3nP0J1+VnPEP8FqGRCdfrSowwtay57viAwG6SUV8rci02twKJ60fQKY+6vmrWAqwt1s4rVXY27/jBVdbWUVjbgMCuoDi9KWSCnUpELlho1pYxdF64g+iYYuF5FrGcLu58E2XG1F9Vdhaq5c0LFat8UVwDN7qLoWi9iAsQo3LrTyYub9WwPxRBTsPXBS+wVB9JKV237z5ZTnaQ/ulVnT/tFAySG4zISiIjMw+kwvVlGTGI01C2aVFm5hkK5Bc3mZNu9fgNoGlpBhJJsDieoHVkyclNINngHGwdmsQXqUHVPfqDq8GD31bDp6SimEQk0FCa5MbNMfG6GxtC8hEpYWIKDPyk53ibtcaa9zA2Unnir2dA3hpBAMQSXPsVhKcbC9wU+RqMcebuYtiCYoORYi+F3fmDqjlkclN5+SNFEjK4P06zMTzI7N8XM52mikWEGB19zMPyFnc8jKFkHk/NQtDI/JVYPp5pr+fqtg8R8O3OTHUyPnWH8/VkiwXMMvzpPNNxFZ1ejrHUba1SXL49CVxpabPFwuecQ8cUWWG4hudhM8keTzJsh2cr9u0dRdKnMmb0215/izHST0H0WL61tNTx+dILRyGmpsIn+Zyfp7m7ArPqwOX6v8RX4lzMFuoTut3opluHy+HH7ApgVL3vNnn9qC782qUsuQy/3GxbYHSlFmUbl+WFrvof5FhT6tu4P7C+Lrbq87mf9NAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "the shadow",
            "title": "the shadow",
            "src": "/static/bca4a9dc2932d69617fb83ebc374bad6/9a86a/demo7.png",
            "srcSet": ["/static/bca4a9dc2932d69617fb83ebc374bad6/a2ead/demo7.png 259w", "/static/bca4a9dc2932d69617fb83ebc374bad6/6b9fd/demo7.png 518w", "/static/bca4a9dc2932d69617fb83ebc374bad6/9a86a/demo7.png 792w"],
            "sizes": "(max-width: 792px) 100vw, 792px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <h2>{`Creating `}<a href="https://www.w3schools.com/css/css3_animations.asp" target="_blank">{`animation`}</a></h2>
    <p>{`We have all the components, it's time for the fancy animation. We will use css
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`animation`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`keyframe`}</code>{` to define rules for such animation.`}</p>
    <h3>{`Moving sun`}</h3>
    <p>{`Below is how we do it in css.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$cubic-bezier`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`cubic-bezier`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0.4`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0.49`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.sun `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`z-index`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$sun-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$sun-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`overflow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` hidden`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #fd7e08`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$frame-size`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` 2 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$sun-size`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` 2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`animation`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` sun-movement 4s `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$cubic-bezier`}</span>{` infinite`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@keyframes`}</span>{` sun-movement`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`0% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rotate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`-80deg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`30% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rotate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`-20deg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`100% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rotate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`70deg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`At this step, the sun is actually spinning around itself. I have made the sun square so that we can see the spinning.`}</p>
    <div {...{
      "style": {
        "textAlign": "center",
        "margin": "0 auto",
        "marginBottom": "20px"
      }
    }}>{`
    `}<img parentName="div" {...{
        "src": "/8f95d63aa210525f9fb7140a5a5d5264/demo8.gif",
        "alt": "spinning-sun"
      }}></img>
    </div>
    <p>{`But here is the magic, if we change `}<a target="_blank" href="https://www.w3schools.com/cssref/css3_pr_transform-origin.asp">{`transform-origin`}</a>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`50% 400%`}</code>{`, then we will have our sunrise and sunset.
This is because `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transform-origin`}</code>{` allows us to change the position of transformed elements.
In other words, the position where the rotation based on has changed. `}</p>
    <p>{`The default `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transition-origin`}</code>{` is 50% 50% which is at the center of the element.
In our case, it is at the center of the sun. When we give it a value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`50% 400%`}</code>{`, we have moved that position along y-axis four times the height of the sun.
I chose the value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`400%`}</code>{` because it would make the sun move across the frame. You can definitely choose whichever you find fit. `}</p>
    <p>{`However, we need to place the sun in the middle of the frame for the sun to move across the frame as we expected.
That is why I mentioned above the left position is really important. `}</p>
    <h3>{`Animating the pyramid`}</h3>
    <p>{`We would like to see two sides of the pyramid changing colors as the sun moves. We can define one `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`keyframe`}</code>{`
and reuse for the both triangles with opposite animation direction.
To reverse the animation, we can declare `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`animation-direction: reverse`}</code>{` or using inline value on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`animation`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.pyramid-left `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #f4f4f4`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip-path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`polygon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0% 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 100% 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 50% 0%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`   `}<span parentName="span" {...{
              "className": "token property"
            }}>{`animation`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` pyramid-shading 4s `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$cubic-bezier`}</span>{` infinite reverse`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.pyramid-right `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` 2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #dddada`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -1px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip-path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`polygon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0% 0%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 100% 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 30% 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` 2 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`animation`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` pyramid-shading 4s `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$cubic-bezier`}</span>{` infinite`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@keyframes`}</span>{` pyramid-shading`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`0% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #dddada`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`30% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
     `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #dddada`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`60% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #f4f4f4`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`100% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #f4f4f4`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "style": {
        "textAlign": "center",
        "margin": "0 auto",
        "marginBottom": "20px"
      }
    }}>{`
    `}<img parentName="div" {...{
        "src": "/18a4c20f1fce109d965c30ac46c8196d/demo9.gif",
        "alt": "spinning-sun"
      }}></img>
    </div>
    <h3>{`Animating the shadow`}</h3>
    <p>{`To animate the shadow, we need to change the pointy corner of the triangle to different position in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`keyframe`}</code>{`.
That pointy angle would move from the right to the left of the pyramid as the sun moves. `}</p>
    <p>{`We can do that by modifying the third arguments of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`polygon`}</code>{` to run from 140% to -40% of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`x-axis`}</code>{`
And to make the shadow shrink while the sun is at the top of the pyramid, we can scale the shadow to a value smaller than 1 at the middle of our animation cycle.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.shadow `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform-origin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 50% 0%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #80808080`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 50%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip-path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`polygon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 140% 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`animation`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` shadow-movement 4s `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$cubic-bezier`}</span>{` infinite`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@keyframes`}</span>{` shadow-movement`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`0% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scaleY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip-path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`polygon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 140% 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`30% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scaleY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip-path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`polygon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      70% 100%
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`50% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scaleY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0.4`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`75% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scaleY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`100% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`scaleY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`clip-path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`polygon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-width-left`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$pyramid-left-position`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` -40% 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h4>{`Creating the animation for the frame`}</h4>
    <p>{`Last but not least, if you look at the demo, you would see that the frame actually has the effect of fade-in and fade-out as the sun goes up and down.
To make it simple, I prefer to create the fading effect for the frame instead of creating fading effect for the sky and the ground.
What we are doing here is to make the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`opacity`}</code>{` change value from 0.3 to 1 in the animation cycle. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "language-scss"
      }}><code parentName="pre" {...{
          "className": "language-scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.frame `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$frame-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$frame-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #000`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 50%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`overflow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` hidden`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`animation`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` fading 4s ease infinite`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@keyframes`}</span>{` fading`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`0% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`30% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`60% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`100% `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`And we have created an animated sunrise and sunset over a pyramid with pure CSS.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`Here are a few notes to take away from this tutorial`}</p>
    <ul>
      <li parentName="ul">{`Transition-origin can be helpful to control transform movement in some cases.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`clip-path`}</code>{` could be very handy when it comes to clipping shape of an element.`}</li>
      <li parentName="ul">{`Animation direction could help to reduce creating two animation rules in some cases (changing colors two sides of the pyramid)`}</li>
    </ul>
    <p>{`That's it! Thank you reading!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      